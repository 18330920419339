<template>
    <div class="w-100">
        <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            height="600"
            type="image"
        >
        </v-skeleton-loader>
        <v-container
            v-else
            class="d-flex"
        >
            <v-row>
                <v-col class="text-center hidden-sm-and-down" cols="5" v-show="!disableImage">
                    <img
                        alt="Wizard Gaming" class="hero-image ma-auto"
                        src="/img/connect-wallet/revolutionary-nft-game.png"
                    />
                </v-col>
                <v-col cols="12" :class="{'col-md-7':!disableImage}">
                    <v-stepper
                        v-model="step"
                        max-width="750px"
                        :width="screenWidth"
                        min-width="auto"
                        vertical
                        class="w-100 mx-auto"
                    >
                        <v-stepper-header class="d-flex align-center justify-start">
                            <h4 class="ml-8">
                                Connect With Web3 Wallet
                            </h4>
                        </v-stepper-header>
                        <v-stepper-step
                            :complete="step > 1"
                            step="1"
                        >
                            Install Web3 Wallet
                        </v-stepper-step>
                        <v-stepper-content
                            step="1"
                        >
                            <v-card class="pa-4">
                                <v-card-text>
                                    <v-alert
                                        text
                                        type="info"
                                    >
                                        <span>
                                            Please download MetaMask to be able to connect with the dApp.
                                        </span>
                                    </v-alert>
                                </v-card-text>
                                <v-card-text
                                    class="d-flex flex-wrap align-center justify-center justify-md-space-between">
                                    <v-btn
                                        :loading="processing"
                                        class="my-2"
                                        href="https://metamask.io/download/"
                                        target="_blank"
                                    >
                                        Install MetaMask
                                    </v-btn>
                                    <v-btn
                                        :loading="processing"
                                        class="my-2"
                                        @click="performStep1"
                                    >
                                        Confirm
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 2"
                            step="2"
                        >
                            Connect to the supported network
                        </v-stepper-step>
                        <v-stepper-content step="2">
                            <v-card class="pa-4">
                                <v-card-text>
                                    <v-alert
                                        text
                                        type="info"
                                    >
                                        <span>
                                            Your wallet is currently using an unsupported network.
                                        <br>
                                            Please connect to the <b>Binance Smart Chain {{ chainName }}</b> network.
                                        </span>
                                    </v-alert>
                                </v-card-text>
                                <v-card-text
                                    class="d-flex flex-wrap align-center justify-center justify-md-space-between">
                                    <v-btn
                                        :loading="processing"
                                        class="my-2"
                                        @click="addNetwork"
                                    >
                                        Add {{ chainName }} Network
                                    </v-btn>
                                    <v-btn
                                        :loading="processing"
                                        class="my-2"
                                        @click="switchNetwork"
                                    >
                                        Switch to {{ chainName }} Network
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 3"
                            step="3"
                        >
                            Connect with MetaMask
                        </v-stepper-step>
                        <v-stepper-content step="3">
                            <v-card class="pa-4">
                                <v-card-text>
                                    <v-alert
                                        text
                                        type="info"
                                    >
                                        <span>
                                            You are ready to connect your dApp with MetaMask.
                                            <br>
                                            To do so, click the button below.
                                        </span>
                                    </v-alert>
                                </v-card-text>
                                <v-card-text
                                    class="d-flex flex-wrap align-center justify-center">
                                    <v-btn
                                        :loading="processing"
                                        class="my-2"
                                        x-large
                                        @click="connectMetamask"
                                    >
                                        <svg class="mr-2" stroke-linejoin="round"
                                             style="height:30px;"
                                             viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M274.1 35.5l-99.5 73.9L193 65.8z" fill="#e2761b" stroke="#e2761b"/>
                                            <g fill="#e4761b" stroke="#e4761b">
                                                <path
                                                    d="M44.4 35.5l98.7 74.6-17.5-44.3zm193.9 171.3l-26.5 40.6 56.7 15.6 16.3-55.3zm-204.4.9L50.1 263l56.7-15.6-26.5-40.6z"/>
                                                <path
                                                    d="M103.6 138.2l-15.8 23.9 56.3 2.5-2-60.5zm111.3 0l-39-34.8-1.3 61.2 56.2-2.5zM106.8 247.4l33.8-16.5-29.2-22.8zm71.1-16.5l33.9 16.5-4.7-39.3z"/>
                                            </g>
                                            <path
                                                d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z"
                                                fill="#d7c1b3" stroke="#d7c1b3"/>
                                            <path d="M138.8 193.5l-28.2-8.3 19.9-9.1zm40.9 0l8.3-17.4 20 9.1z"
                                                  fill="#233447"
                                                  stroke="#233447"/>
                                            <path
                                                d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z"
                                                fill="#cd6116" stroke="#cd6116"/>
                                            <path
                                                d="M87.8 162.1l23.6 46-.8-22.9zm120.3 23.1l-1 22.9 23.7-46zm-64-20.6l-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0l-2.7 18 1.2 45 6.7-34.1z"
                                                fill="#e4751f" stroke="#e4751f"/>
                                            <path
                                                d="M179.8 193.5l-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3l.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z"
                                                fill="#f6851b" stroke="#f6851b"/>
                                            <path
                                                d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z"
                                                fill="#c0ad9e" stroke="#c0ad9e"/>
                                            <path
                                                d="M177.9 230.9l-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z"
                                                fill="#161616"
                                                stroke="#161616"/>
                                            <path
                                                d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z"
                                                fill="#763d16" stroke="#763d16"/>
                                            <path
                                                d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z"
                                                fill="#f6851b" stroke="#f6851b"/>
                                        </svg>
                                        Connect MetaMask
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import DialogBox from "../components/dialog-box";
import PageFiltersSection from "../components/page-filters-section";
import WalletButton from "@/components/walletButton";
import MainButton from "@/components/ui/main-button";
import WalletIntegration from "@/wallet-integration";

export default {
    components: {MainButton, WalletButton, PageFiltersSection, DialogBox},

    props: {
        disableImage: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: true,
            processing: false,
            step: 1,
        };
    },

    mounted: function () {
        this.loading = true;
        if (!this.getConnectedWallet()) {
            this.init();
        } else {
            window.addEventListener('event-set-connected-wallet', () => {
                if (!this.getConnectedWallet()) {
                    this.init();
                }
            });
        }
    },
    destroyed() {
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        chainName() {
            return process.env.VUE_APP_BLOCKCHAIN_CHAIN_NAME;
        },
        screenWidth() {
            if (window.innerWidth < 500) {
                return window.innerWidth - 40;
            }
            return 'auto';
        }
    },
    methods: {
        async init() {
            this.step = 1;
            this.loading = false;
            this.performStep1();
        },
        getConnectedWallet() {
            const connectedWallet = localStorage.getItem('connectedWallet');
            return connectedWallet === 'null' ? null : connectedWallet;
        },
        addNetwork() {
            this.processing = true;
            WalletIntegration.addUserNetwork()
                .then(() => {
                    this.switchNetwork();
                })
                .catch();
        },
        switchNetwork() {
            this.processing = true;
            WalletIntegration.switchUserNetwork()
                .then(() => {
                    this.performStep2();
                })
                .catch();
        },
        async connectMetamask() {
            this.processing = true;
            await WalletIntegration.connectMetamask().catch();
            this.$gtm.trackEvent({
                'event': 'GA-Connect-Wallet-Metamask',
            });
            this.performStep3();
        },
        performStep1() {
            this.step = 1;
            this.processing = true;
            const web3Installed = WalletIntegration.isWeb3Installed();
            this.processing = false;
            if (web3Installed) {
                this.performStep2().then();
            }
        },
        async performStep2() {
            this.processing = true;
            this.step = 2;
            const currentChainOk = await WalletIntegration.isCurrentChainIdOk();
            this.processing = false;
            if (currentChainOk) {
                this.performStep3();
            }
        },
        performStep3() {
            this.processing = true;
            this.step = 3;
            this.processing = false;
        },
    },

};

</script>

<style lang="scss" scoped>
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
        height: 100%;
    }
}

img.hero-image {
    width: 80%;
    max-width: 475px;
    height: auto;
}

.theme--dark.v-stepper {
    background: var(--v-primary-darken1);
}

</style>
